const data = [
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure1.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure2.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure3.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure4.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure5.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure6.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure7.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/Campus-tour-and-infrastructure8.png",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/infrastructure81.jpg",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/infrastructure82.jpg",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/infrastructure83.jpg",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/infrastructure84.jpg",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/infrastructure85.jpg",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/infrastructure86.jpg",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/infrastructure87.jpg",
  "https://www.chinmayavvdelhi.ac.in/images/Blackele/infrastructure88.jpg",
];

export default data.reverse();
