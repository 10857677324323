import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import data from "./data";
import SEO from "../../seo";

export default function Infrastructure() {
  return (
    <Layout>
      <SEO
        title="Infrastructure | Chinmaya Vidyalaya Vasant Vihar Delhi"
        description="The Chinmaya Vidyalaya, Vasant Vihar Infrastructure and school campus covers a total area of 3.75 acres. Strategically designed student-friendly campus"
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="infra">
        <h1 className="heading">Infrastructure</h1>

        <p className="infra__text">
          The Chinmaya Vidyalaya, Vasant Vihar school campus covers a total area
          of 3.75 acres. The design of the campus is student-friendly and
          aesthetically pleasing, with the best of equipment for an environment
          conducive to learning. Great attention to detail has been paid in
          concept and spirit to make the campus a vibrant and dynamic one, with
          an abundance of greenery and joyful spaces.
          <br />
          <br />
          The Vidyalaya classrooms feature audio-visual facilities for smart
          learning. These work in tandem with the custom ERP student management
          software that digitally connects the school ecosystem for an optimized
          teaching-learning process. This is further synergized by practical
          learning at the subject labs which are equipped with the latest
          technology.
          <br />
          <br />
          A well-stocked Library and Resource centre not only features
          treasured, celebrated books on various topics, but also quiet
          storytelling corners and even a coffee bar where students can read and
          relax.
          <br />
          <br />
          The in-house medical centre is on a stand-by mode, to meet any
          exigencies or health concerns, and provides timely medical attention
          to students as needed.
          <br />
          <br />A sprawling, inviting area for physical activities, and being in
          tune with nature provides a multitude of opportunities for recreation.
        </p>

        <div className="infra__images">
          <div className="row">
            {data.map((item) => (
              <div className="col col-12 col-md-6 col-xl-4">
                <img className="infra__images__img" src={item}></img>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
